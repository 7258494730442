<template>
  <div class="container mescroll-touch">
    <div class="header">
      <div class="bannerBox" v-if="isBanner">
        <van-swipe ref="vanSwipe" class="my-swipe" :autoplay="3000" indicator-color="#CA001B" :show-indicators="false" @change="onSwipeChange">
          <van-swipe-item v-for="(item, index) in bannerList" :key="index" @click="imgclick(item.UrlLink)">
            <img class="bannerImg" :src="item.File_Url" />
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="indicatorsBox" v-if="isBanner">
        <span class="swipe_indicator" :class="{'indicator_active_gradient': index == currentIndicator}" v-for="(item, index) in bannerList.length" :key="index" @click="indicatorChange(index)"></span>
      </div>
    </div>

    <div class="menu" v-if="ColumnList.length > 0">
      <div class="menu_item" v-for="(item, index) in ColumnList" :key="index" @click="menuClick(item)">
        <wx-open-launch-weapp
          id="launchBtn"
          appid="wx75791703225a9a69"
          path="pages/index/index"
          v-if="item.Appid && item.PagePath"
        >
          <script type="text/wxtag-template">
            <style>
              .btn {
                <!-- width: calc(100% / 4);
                height: 80px; -->
                background: orange;
              }
            </style>
            <div class="btn"></div>
          </script>
        </wx-open-launch-weapp>
        <!-- <template v-else>  -->
        <img :src="item.File_Url" />
        <span>{{item.Name}}</span>
        <!-- </template> -->
      </div>
    </div>

    <div class="moduleBox">
      <div class="titleBox">
        <div class="title">
          <img class="screenIcon" src="@/assets/images/new_home_icon_alt@2x.png" />
          <span>最新病例</span>
        </div>
        <div class="more" @click="toZxbl()">
          <span>更多</span>
          <img src="@/assets/images/more_yellow.png" />
        </div>
      </div>
      <div class="recommendBox" v-if="ZxblList.length > 0">
        <div class="recommendItem" v-for="(item, index) in ZxblList" :key="index" @click="toContentPage(item.Id, item.Type)">
          <div class="item_img">
            <img class="item_cover" :src="item.Thumbnail" />
            <img class="playIcon" v-if="item.Type == 1 || item.Type == 3" src="@/assets/images/play_comm_icon@2x.png" />
          </div>
          <div class="item_content">
            <span class="item_title">{{item.Title}}</span>
            <div class="item_flexbox">
              <p class="item_time">{{item.yyyymmdd}}</p>
              <div class="item_nums">
                <div class="nums">
                  <img class="watchIcon" src="@/assets/images/watch_yellow.png" />
                  <span>{{item.WatchCount}}</span>
                </div>
                <div class="nums">
                  <img class="likeIcon" src="@/assets/images/like_yellow.png" />
                  <span>{{item.LikeCount}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="moduleBox">
      <div class="titleBox">
        <div class="title">
          <img class="screenIcon" src="@/assets/images/new_home_icon_alt@2x.png" />
          <span>最新活动</span>
        </div>
        <div class="more" @click="toZxhd()">
          <span>更多</span>
          <img src="@/assets/images/more_yellow.png" />
        </div>
      </div>
      <div class="recommendBox" v-if="ZxhdList.length > 0">
        <div class="recommendItem" v-for="(item, index) in ZxhdList" :key="index" @click="toContentPage(item.Id, item.Type)">
          <div class="item_img">
            <img class="item_cover" :src="item.Thumbnail" />
            <img class="playIcon" v-if="item.Type == 1 || item.Type == 3" src="@/assets/images/play_comm_icon@2x.png" />
          </div>
          <div class="item_content">
            <span class="item_title">{{item.Title}}</span>
            <div class="item_flexbox">
              <p class="item_time">{{item.yyyymmdd}}</p>
              <div class="item_nums">
                <div class="nums">
                  <img class="watchIcon" src="@/assets/images/watch_yellow.png" />
                  <span>{{item.WatchCount}}</span>
                </div>
                <div class="nums">
                  <img class="likeIcon" src="@/assets/images/like_yellow.png" />
                  <span>{{item.LikeCount}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import utils from "../../common/utils/utils";
export default {
  name: 'EnteringZone',
  data() {
    return {
      bannerList: [],
      currentIndicator: 0,
      isBanner: false,
      ColumnList: [],
      ZxblList: [],
      ZxhdList: []
    }
  },
  created() {
    this.getBanner();
    this.getColumn();
    this.getZxblList();
    this.getZxhdList();
    this.initWxConfig();
    sessionStorage.setItem("entry", 'enteringZone');
  },
  methods: {
    // 获取Banner
    getBanner() {
      this.$axios.get('/Api/api/Web/ImgFile/GetImgFileListByCode?Code=zjzq').then(res => {
        this.bannerList = res.Data;
        if(this.bannerList.length > 0) {
          this.isBanner = true;
        }
      })
    },
    // 栏目菜单
    getColumn() {
      this.$axios.get('/Api/api/Web/ImgFile/GetIFLstCode?Code=zjzq').then(res => {
        this.ColumnList = res.Data;
      })
    },
    // 最新病例
    getZxblList() {
      this.$axios.get('/Api/api/Web/Article/GetNew?Code=cnvblzq').then(res => {
        this.ZxblList = res.Data;
      })
    },
    // 最新活动
    getZxhdList() {
      this.$axios.get('/Api/api/Web/Article/GetNew?Code=jchd').then(res => {
        this.ZxhdList = res.Data;
      })
    },
    initWxConfig() {
      let that = this;
      const entryLink = utils.getEntryLink();
      that.$axios
        .get("/Api/Api/web/ShareLog/wxHelp?backUrl=" + entryLink + '&type=2')
        .then((res) => {
          wx.config({
            debug: false,
            appId: 'wxc8567b9f90662acd', // 服务号
            // appId: 'wxf77916b15f3905df', // 订阅号
            // appId: res.Data.AppId,
            timestamp: res.Data.TimesTamp,
            nonceStr: res.Data.NonceStr,
            signature: res.Data.She1Str,
            jsApiList: ["chooseImage", "previewImage"],
            openTagList: ['wx-open-launch-weapp']
          });
        });
    },
    imgclick(UrlLink) {
      let objExp=new RegExp(/^(f|ht)tps?:\/\//i);
      if(UrlLink) {
				if(objExp.test(UrlLink)) {
				  location.href = UrlLink;
				}else{
					this.$router.push(UrlLink)
				}
      }
    },
    onSwipeChange(index) {
      this.currentIndicator  = index;
    },
    indicatorChange(index) {
      this.currentIndicator  = index;
      this.$refs.vanSwipe.swipeTo(index);
    },
    menuClick(item) {
      if(item.Appid && item.PagePath) {
        console.log('小程序跳转');
      } else {
        let objExp=new RegExp(/^(f|ht)tps?:\/\//i);
        if(item.UrlLink) {
          if(objExp.test(item.UrlLink)) {
            location.href = item.UrlLink;
          }else{
            this.$router.push(item.UrlLink)
          }
        }
      }
    },
    toZxbl() {
      this.$router.push('/caseZone')
    },
    toZxhd() {
      this.$router.push({
        path: 'EPICZone',
        query: {
          code: 'jchd'
        }
      });
    },
    toContentPage(Id, Type) {
      this.$router.push({
        path: 'contentPage',
        query: {
          id: Id,
          type: Type
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  min-height: 100%;
  // background-color: #F8F8F8;
  background-image: url('../../assets/images/zjzqbg.jpg');
  background-size: 100% 100%;
  padding: 15px;
  box-sizing: border-box;
  .header {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    .bannerBox {
      border-radius: 8px;
      overflow: hidden;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 8px 0px;
      .bannerImg {
        width: 100%;
        display: block;
        border-radius: 8px;
      }
    }
  }
  .menu {
    display: flex;
    flex-wrap: wrap;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 6px;
    margin: 15px 0 20px;
    padding: 6px 0;
    box-shadow: 0 3px 8px 0 rgba(51, 51, 51, .08);
    .menu_item {
      position: relative;
      text-align: center;
      padding: 4px 10px;
      box-sizing: border-box;
      width: calc(100% / 4);
      img {
        display: block;
        width: 40px;
        height: 40px;
        margin: 0 auto;
      }
      span {
        font-size: 12px;
        color: #333333;
      }
    }
  }
  #launchBtn {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
  }
  .moduleBox {
    .titleBox {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      .title {
        width: auto;
        display: flex;
        align-items: center;
        height: 30px;
        border-radius: 30px;
        padding: 0 10px;
        box-sizing: border-box;
        font-size: 14px;
        background-image: linear-gradient(to right, #A3BF01, #FF6900 );
        color: #FFFFFF;
        img {
          display: block;
          width: 16px;
          height: 14px;
          margin-right: 4px;
        }
      }
      .more{
        display: flex;
        align-items: center;
        font-size: 12px;
        color: $themeColor;
        span {
          background: -webkit-linear-gradient(45deg, #A3BF01, #FF6900); /* Chrome, Safari */
          background: linear-gradient(45deg, #A3BF01, #FF6900); /* 标准语法 */
          -webkit-background-clip: text; /* Chrome, Safari */
          background-clip: text;
          -webkit-text-fill-color: transparent; /* Chrome, Safari */
          color: transparent;
        }
        img {
          width: 14px;
          height: 14px;
          margin-left: 4px;
        }
      }
    }
    .recommendBox {
      display: flex;
      margin-bottom: 10px;
      min-height: 50px;
      .recommendItem {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        width: calc(calc(100% / 2) - 5px);
        border-radius: 6px;
        background-color: #FFFFFF;
        overflow: hidden;
        .item_img {
          position: relative;
          .item_cover {
            display: block;
            width: 100%;
          }
          .playIcon {
            position: absolute;
            right: 12px;
            bottom: 12px;
            width: 30px;
            height: 30px;
          }
        }
        .item_content {
          padding: 5px 8px 8px;
          .item_title {
            height: 35px;
            font-size: 13px;
            line-height: 17px;
            color: #333333;
            overflow: hidden;
            text-overflow:ellipsis;
            display:-webkit-box;
            -webkit-box-orient:vertical;
            -webkit-line-clamp:2;
            word-wrap: break-word;
          }
          .item_flexbox {
            display: flex;
            justify-content: space-between;
          }
          .item_time {
            font-size: 12px;
            color: #999999;
            line-height: 24px;
          }
          .item_label {
            display: flex;
            flex-wrap: wrap;
            span {
              display: inline-block;
              height: 20px;
              line-height: 20px;
              border-radius: 2px;
              padding: 0 6px;
              margin-right: 6px;
              margin-bottom: 5px;
              background-color: rgba(255, 73, 98, 0.15);
              font-size: 11px;
              color: #CA001B;
            }
          }
          .item_nums {
            display: flex;
            justify-content: flex-end;
            .nums {
              display: flex;
              align-items: center;
              .watchIcon {
                width: 14px;
                margin: 0 2px 0 6px;
              }
              .likeIcon {
                width: 11px;
                margin: 0 2px 0 6px;
              }
              span {
                font-size: 11px;
                color: #666666;
              }
            }
          }
        }
      }
      .recommendItem:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
  .moduleBox:not(:last-of-type) {
    margin-bottom: 20px;
  }
}
</style>